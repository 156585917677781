<template>
    <div class="Admission">
        <div class="container">
            <div class="flex flex-col justify-center items-center py-10">
                <h1 class="adminssion-big-title bold uppercase font-bebas leading-none">
                    Admission
                </h1>
                <p class="text-center">
                    We strive to make admission process as smooth as possible
                    for you and your child.
                </p>
            </div>
        </div>
        <div class="image-wrapper"></div>
        <!-- <img src="../assets/adminssion.png" /> -->
        <div class="container">
            <div class="flex flex-col justify-center items-center py-10">
                <h1 class="orange-text text-4xl uppercase font-bebas">
                    Admission Requirements
                </h1>
                <div class="rectangle-orange mt-6"></div>
                <p class="text-center mt-6">
                    P.I.T.H. accepts children from ages 3 to 6. Please complete
                    our online Interest Form and our associates will be in touch
                    with you soonest.
                </p>
                <div class="mt-6">
                    <a href="https://app.littlelives.com/enrollment#/interest-form/100637-p-i-t-h-kindergarten"
                        target="_blank" class="font-bold my-6">
                        <button class="button uppercase text-base">
                            Interest Form
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <div class="gray-bg">
            <div class="container flex flex-col justify-center items-center py-10">
                <h1 class="orange-text text-4xl uppercase font-bebas">
                    What We Offer
                </h1>
                <div class="rectangle-orange mt-6"></div>

                <div class="we-offer-conetnt-container grid grid-cols-4 grid-flow-row gap-4 pb-5">
                    <div class="flex items-center bg-white border-l-8 border-red-400 m-5">
                        <p class="m-6">
                            <strong>Pre-Nursery</strong><br />3 years old programme
                        </p>

                    </div>
                    <div class="flex items-center bg-white border-l-8 border-orange-400 m-5">
                        <p class="m-6">
                            <strong>Nursery</strong><br />4 years old programme
                        </p>
                    </div>
                    <div class="flex items-center bg-white border-l-8 border-yellow-400 m-5">
                        <p class="m-6">
                            <strong>K1</strong><br />5 years old programme
                        </p>
                    </div>
                    <div class="flex items-center bg-white border-l-8 border-green-400 m-5">
                        <p class="m-6">
                           <strong>K2</strong><br />6 years old programme
                        </p>
                    </div>
                </div>
                <p class="text-sm text-center orange-text">
                    * We offer Half-Day and Full-Day programme for all levels.
                </p>
                <p class="text-sm text-center orange-text">
                    * The acceptance age for each level is based on the Year that the child is born.
                </p>
            </div>
        </div>
        <div class="container operating-hour-container">
            <div class="container flex flex-col justify-center items-center py-10">
                <h1 class="orange-text text-4xl uppercase font-bebas">
                    Operating Hours
                </h1>
                <div class="rectangle-orange mt-6"></div>
                <div class="text-center mt-6">
                    <strong>Monday to Friday:</strong>
                    <p>8.00am to 5.00pm</p>
                </div>

                <div class="text-center mt-6">
                    <strong>Saturday & Sunday:</strong>
                    <p>Closed</p>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
export default {
    name: "Admission",
    components: {},
};
</script>


<style scoped lang="less">
.Admission {
    background-color: #ededed;
}
.image-wrapper {
    width: 100%;
    height: 360px;
    background-image: url("../assets/admission-page.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 25%;
}
.orange-text {
    color: #de6a07;
}
.gray-bg {
    background-color: #e2e2e2;
}
.button {
    background-color: #de6a07;
    border: none;
    color: white;
    padding: 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
}
.adminssion-big-title {
    font-size: 54px;
}
.step-title {
    color: #00baff;
}
@media (min-width: 280px) and (max-width: 480px) {
    .Admission {
        h1 {
            @apply text-2xl;
        }
    }
    .we-offer-conetnt-container {
        @apply grid-cols-none;
    }
    .image-wrapper {
        background-position: 42% 50%;
    }
}
@media (min-device-width: 481px) and (max-device-width: 767px) {
    .we-offer-conetnt-container {
        @apply grid-cols-1;
    }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
    .we-offer-conetnt-container {
        @apply grid-cols-2;
    }
}
</style>
